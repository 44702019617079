import type { RouteRecordRaw } from "vue-router";

const universalBillRoute: Array<RouteRecordRaw> = [
    {
        path: "/admin/universal-bill",
        name: "universal-bill",
        component: () => import("@/views/admin/universal_bill/UniversalBillList.vue"),
        meta: {
            pageTitle: "bill",
            breadcrumbs: ["bill"],
        },
    },
    // {
    //     path: "/admin/bill/detail",
    //     name: "bill-detail",
    //     component: () => import("@/views/admin/bill/BillDetail.vue"),
    //     meta: {
    //         pageTitle: "bill",
    //         breadcrumbs: ["bill", "detail"],
    //     },
    // },
];

export default universalBillRoute;
