<template>
  <RouterView />
</template>

<script lang="ts">
import { defineComponent, nextTick, onBeforeMount, onMounted } from 'vue'
import { RouterView } from 'vue-router'
import { useConfigStore } from '@/stores/config'
import { useThemeStore } from '@/stores/theme'
import { useBodyStore } from '@/stores/body'
import { themeConfigValue } from '@/core/helpers/config'
import { initializeComponents } from '@/core/plugins/keenthemes'
import { useConfigAdmissionStore } from '@/stores/admission'
import { useInstitutionColorProfileStore } from '@/stores/colorProfile'
import chroma from 'chroma-js'
import { useConfigRegisterStore } from '@/stores/register'

export default defineComponent({
  name: 'app',
  components: {
    RouterView,
  },
  setup() {
    const configStore = useConfigStore()
    const themeStore = useThemeStore()
    const bodyStore = useBodyStore()
    const configAdmissionStore = useConfigAdmissionStore()
    const configRegisterStore = useConfigRegisterStore()
    const institutionStore = useInstitutionColorProfileStore()

    onBeforeMount(() => {
      /**
       * Overrides the layout config using saved data from localStorage
       * remove this to use static config (@/core/config/DefaultLayoutConfig.ts)
       */
      configStore.overrideLayoutConfig()

      /**
       *  Sets a mode from configuration
       */
      themeStore.setThemeMode(themeConfigValue.value)
    })

    onMounted(async () => {
      configAdmissionStore.loadFromLocalStorage()
      if (configAdmissionStore.configAdmission.length === 0) {
        await configAdmissionStore.getAllConfiguration()
      }

      configRegisterStore.loadFromLocalStorage()
      if (configRegisterStore.configRegister.length === 0) {
        await configRegisterStore.getAllConfiguration()
      }
      await nextTick(async () => {
        initializeComponents()

        const rootKt = document.querySelector('[data-bs-theme=light]') as HTMLElement
        const rootElPlus = document.querySelector(':root') as HTMLElement

        await institutionStore.getInstitutionConfigCommon()

        if (institutionStore.primary_color) {
          let f = chroma.scale([chroma(institutionStore.primary_color).css(), 'white'])

          if (rootKt) {
            rootKt.style.setProperty('--bs-primary', chroma(institutionStore.primary_color).css())
            rootKt.style.setProperty(
              '--bs-primary-rgb-string',
              chroma(institutionStore.primary_color).rgb(),
            )
            rootKt.style.setProperty('--bs-primary-light', f(0.9))
            rootKt.style.setProperty(
              '--bs-primary-active',
              chroma(chroma(institutionStore.primary_color).brighten(0.2)).css(),
            )
            rootKt.style.setProperty('--bs-text-primary', chroma(institutionStore.primary_color).css())
          }
          if (rootElPlus) {
            rootElPlus.style.setProperty(
              '--el-color-primary',
              chroma(institutionStore.primary_color).css(),
            )
            rootElPlus.style.setProperty('--el-color-primary-light-3', f(0.3))
            rootElPlus.style.setProperty('--el-color-primary-light-5', f(0.5))
            rootElPlus.style.setProperty('--el-color-primary-light-7', f(0.7))
            rootElPlus.style.setProperty('--el-color-primary-light-8', f(0.8))
            rootElPlus.style.setProperty('--el-color-primary-light-9', f(0.9))
          }
        }


        bodyStore.removeBodyClassName('page-loading')
      })
    })
  },
})
</script>

<style lang="scss">
@import "bootstrap-icons/font/bootstrap-icons.css";
@import "apexcharts/dist/apexcharts.css";
@import "quill/dist/quill.snow.css";
@import "animate.css";
@import "sweetalert2/dist/sweetalert2.css";
@import "nouislider/distribute/nouislider.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "socicon/css/socicon.css";
@import "line-awesome/dist/line-awesome/css/line-awesome.css";
@import "dropzone/dist/dropzone.css";
@import "@vueform/multiselect/themes/default.css";
@import "prism-themes/themes/prism-shades-of-purple.css";
@import "element-plus/dist/index.css";

// Main demo style scss
@import "assets/fonticon/fonticon.css";
@import "assets/keenicons/duotone/style.css";
@import "assets/keenicons/outline/style.css";
@import "assets/keenicons/solid/style.css";
@import "assets/sass/element-ui.dark";
@import "assets/sass/plugins";
@import "assets/sass/style";

#app {
  display: contents;
}
</style>
