import type { RouteRecordRaw} from "vue-router";

const appsLoginRoute: Array<RouteRecordRaw> = [
  // {
  //   path: "/sign-in",
  //   name: "apps-login",
  //   component: () => import("@/views/apps/auth/login/Login.vue"),
  //   meta: {
  //     pageTitle: "signIn",
  //   },
  // },
];

export default appsLoginRoute;
