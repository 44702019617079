import { defineStore } from 'pinia'

// import ApiService from '@/core/services/ApiService'
import type { IAdmissionColorData } from '@/core/types/color'

export const useInstitutionColorProfileStore = defineStore('institution_profile', {
  state: () =>
    ({
      primary_color: '',
    } as IAdmissionColorData),
  persist: true,
  getters: {},
  actions: {
    async getInstitutionConfigCommon() {
      try {
        // const { data } = await ApiService.get('institutions/config', undefined, "public")
        // const institutionData: any = data.payload.data

        const institutionData: IAdmissionColorData = {
          primary_color: "#0056D2",
        }

        this.primary_color = institutionData.primary_color
      } catch (e: any) {
        throw new Error(e)
      }
    },
  },
})
